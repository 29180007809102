import React from 'react';

const Spinner = () => {
  return (
    <div className='spinner'>
      <div className="loadingio-spinner-spinner-k544776nme"><div className="ldio-zhofok5zwpp">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>
</div>
  );
};


export default Spinner;
