import React, { useEffect } from 'react';
import dict from '../utils/dictionary';

const Footer = ({ secondDisclaimer }) => {
	const dictionary = dict()['footer'];

	return (
		<div id='contact'>
			<section className='p-0'>
				<div className='container-fluid'>
					<div className='bottom-section'>
						<div className='row'>
							<div className='col-md-12 p-0'>
								<div className='address-bar'>
									<div>
										<h2>{dictionary['contactUs']}</h2>
										<ul className='footer-style'>
											<li>
												<div className='footer-address'>{/* <a href='https://www.instagram.com/legacy.smurfs'>legacy.smurfs</a> */}</div>
											</li>
											<li>
												<div className='footer-icon'>
													<i style={{ color: '#d86400', fontSize: '29px' }} className='fab fa-instagram'></i>
												</div>
												<div className='footer-address'>
													<a target='_blank' rel='noopener' href={`https://www.instagram.com/${dictionary['instagramId']}`}>
														{dictionary['instagramId']}
													</a>
												</div>
											</li>
											<li>
												<div className='footer-icon'>
													<i style={{ color: '#6f84d2', fontSize: '29px' }} className='fab fa-discord'></i>
												</div>
												<div className='footer-address'>
													<a target='_blank' rel='noopener' href={dictionary['discordLink']}>
														{dictionary['discordText']}
													</a>
												</div>
											</li>
											<li>
												<div className='footer-icon'>
													<i style={{ color: 'white', fontSize: '29px' }} className='fas fa-envelope'></i>
												</div>
												<div className='footer-address'>
													<a target='_blank' href={`mailto:${dictionary['email']}`}>
														{dictionary['email']}
													</a>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className='skins-title text-center'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12 col-sm-12 col-text-center d-flex d-align-center'>
							<span style={{ color: '#929292' }}>{!secondDisclaimer ? dictionary['disclaimer'] : dictionary['disclaimer2']}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
