import React from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import dict from '../utils/dictionary';

const Success = () => {
	const dictionary = dict()['success'];
	const dictionary2 = dict()['footer'];

	let history = useHistory();
	const location = useLocation();
	const orderId = queryString.parse(location.search).payment_id;
	if (!orderId) {
		history.push('/');
	}
	return (
		<div className='success-page payment-page'>
			<div className='innerpage-decor d-none d-lg-block'>
				<div className='innerpage-circle1'>
					<img style={{ width: '60px' }} src={`${process.env.PUBLIC_URL}/assets/images/beehappy-emote.webp`} alt='bee icon' />
				</div>
				<div className='innerpage-circle2'>
					<img src={`${process.env.PUBLIC_URL}/assets/images/velkoz.webp`} alt='velkoz icon' />
				</div>
			</div>
			<div className='text-center'>
				<h2 className='f-bold'>{dictionary['title']}</h2>
				<h2>
					{dictionary['orderIdText']} {orderId}
				</h2>
				<div className='col-md-8 offset-md-2 col-12'>
					<h3>{dictionary['firstDesc']}</h3>
					<h3>{dictionary['secondDesc']}</h3>
				</div>
				<div className='mt-5'>
					<a href={`${process.env.PUBLIC_URL}/`} className='btn btn-custom theme-color theme-color pt-2 pb-2'>
						{dictionary['backToMainPageButton']}
					</a>
					<div className='networks-thankyou-pages'>
						<div className='networks-text'>
							<a style={{ color: 'white !important' }} target='_blank' href={dictionary2['discordLink']}>
								<div>
									<i style={{ color: '#6f84d2', fontSize: '29px' }} className='fab fa-discord'></i>
								</div>
							</a>
						</div>

						<div className='networks-text'>
							<a style={{ color: 'white !important' }} target='_blank' href={`https://www.instagram.com/${dictionary2['instagramId']}`}>
								<div>
									<i style={{ color: '#d86400', fontSize: '29px' }} className='fab fa-instagram'></i>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Success;
