export default () => {
	const LANGUAGE = 'br';

	const dictionary = {
		br: {
			// 404
			404: {
				notFound: 'Nada encontrado',
				pageMovedOrNeverExisted: 'A página que esta procurando foi movida ou nunca existiu.',
				backToMainPageButton: 'Voltar para a página inicial',
			},
			// SUCCESS

			success: {
				title: 'Obrigado pela sua compra!',
				orderIdText: 'número da ordem:',
				firstDesc: 'Você receberá um e-mail com sua compra em instantes.',
				secondDesc: 'Guarde este número é o número da sua compra.',
				backToMainPageButton: 'Voltar para a página inicial',
			},
			// FAILED
			failed: {
				title: 'O pagamento foi recusado!',
				orderIdText: '',
				firstDesc: 'Pagamento cancelado ou recusado, tente novamente.',
				secondDesc: '',
				backToMainPageButton: 'Voltar para a página inicial',
			},
			// PENDING
			pending: {
				title: 'Pagamento pendente!',
				orderIdText: 'número da ordem:',
				firstDesc: 'Depois de efetuar o pagamento você receberá sua compra por e-mail.',
				secondDesc: 'Salve o número do pedido para entrar em contato se necessário.',

				backToMainPageButton: 'Voltar para a página inicial',
			},
			// MENU
			menu: {
				home: 'home',
				about: 'sobre',
				accounts: 'contas',
				faq: 'faq',
				skins: 'skins',
				contact: 'contato',
				giveawaysText: 'sorteios',
				giveawaysURL: 'https://instagram.com/mafiasmurfs',
			},
			// HOME
			home: {
				pageTitle: 'Mafia Smurfs - Compre sua Smurf, escolha sua skin',
				mainTextHomePage: 'A solução definitiva para adquirir suas contas unranked',
				normalAccountsButtonText: 'Unranked Normal',
				skinsAccountsButtonText: 'Unranked por skin',
			},
			// FEATURE
			feature: {
				whyChooseUs: 'porque escolher',
				storeName: 'Mafia Smurfs',
				features: {
					1: {
						iconClass: 'fas fa-search-dollar',
						title: 'NÃO PROCURE MAIS',
						text: 'Primeiro site do brasil a ter escolha de skins 100% automático! Você encontrou o lugar certo. A Mafia Smurfs oferece a melhor smurf de lol do mercado, mantendo um preço baixo. Aqui você tem suporte sempre que precisar, somos sua zona de conforto. Fizemos o melhor site por você!',
					},
					2: {
						iconClass: 'fas fa-check',
						title: 'BAIXA TAXA DE BANIMENTO',
						text: 'Nossas contas são criadas usando um método privado para garantir que a taxa de banimento permaneça EXTREMAMENTE baixa. Isso pra garantir a satisfação dos nossos clientes, mesmo com custo adicional pra nós, garantindo nossa qualidade.',
					},
					3: {
						iconClass: 'fas fa-envelope-square',
						title: 'ACESSO COMPLETO',
						text: 'Quando você compra uma conta unranked nossa, ela será 100% sua. Só você terá acesso a sua conta unranked, você nunca vai precisar se preocupar com o "dono original" tentando recuperar a conta, como acontece em outros sites.',
					},
					4: {
						iconClass: 'fas fa-shipping-fast',
						title: 'ENTREGA IMEDIATA',
						text: 'Não perca seu tempo! Depois de fazer sua compra, todos os detalhes da sua conta serão automaticamente enviados por e-mail para você.',
					},
					5: {
						iconClass: 'fas fa-heart',
						title: 'GARANTIA DA CONTA',
						text: 'Oferecemos uma garantia de 30 dias, caso algo aconteça com sua conta por culpa nossa, você recebe outra e sua garantia é renovada por mais 30 dias. A garantia não cobre mal uso, como: Mau comportamento, abandono, uso de scripts, skin changer, trava lobby, etc.',
					},
					6: {
						iconClass: 'fas fa-balance-scale-right',
						title: 'QUALIDADE ACIMA DE QUANTIDADE',
						text: 'Temos orgulho da qualidade de nossas contas unranked. Todas as contas têm jogos de qualidade e vêm com o maior número possível de Essências Azuis e Laranjas para ativar sua skin escolhida, skins ultimate e prestígio são gratuitas para ativar.',
					},
				},
			},
			// FEATURE
			price: {
				countryMpagoScript: 'br',
				title: 'Contas',
				chooseYourRegion: 'Escolha a região',
				regions: {
					las: 'LAS',
					br: 'Brasil',
				},
				writeValidEmail: 'Coloque seu e-mail válido',
				generatePayment: 'Gerar pagamento',
				continue: 'Continuar',
				noStock: 'Sem estoque',
				errorTryAgain: 'Erro, Tente voltar',
				errorNoStock: 'ERRO: Sem estoque disponível.',
				errorPleaseTryAgain: 'ERRO: Por favor, tente novamente.',
				errorCouponInvalidForQuantity: 'ERRO: Cupom inválido para a quantidade do produto.',
				errorCouponExpired: 'ERRO: Cupom Expirado.',
				errorCouponDontExist: 'ERRO: Cupom não existe para o produto selecionado.',
				localCurrency: 'Reais BRASIL',
				localCurrencySymbol: 'R$',
				chooseButton: 'Escolher',
				accountsTitle: 'Contas',
				makeYourPayment: 'Realizar Pagamento',
				price: 'Preço',
				quantity: 'Quantidade',
				coupon: 'Cupom',

				use: 'Usar',
				useCouponToGetDiscount: 'Usar cupom para ter desconto',
				yourEmail: 'Seu e-mail',
			},
			faq: {
				title: 'faq',
				1: {
					iconClass: 'icon fas fa-shield-alt',
					title: 'COMO SABER SE É SEGURO COMPRAR NA MAFIA SMURFS?',
					text: 'Temos orgulho da segurança e satisfação dos nossos clientes. Todas as compras são protegidas pelos métodos de pagamento, o que significa que suas informações de pagamento são 100% seguras e nosso site é protegido por SSL para segurança adicional. Temos uma GARANTIA de 30 dias em todas as nossas contas, portanto, se algo acontecer, você está protegido!',
				},
				2: {
					iconClass: 'icon far fa-credit-card',
					title: 'MÉTODOS DE PAGAMENTO?',
					text: 'Atualmente aceitamos PIX / MercadoPago e cartões de crédito / débito.',
					//  Também criptomoedas: Bitcoin, Ethereum, Litecoin, Cardano, entre outras.',
				},
				3: {
					iconClass: 'icon far fa-clock',
					title: 'QUANTO TEMPO LEVA PARA MINHA CONTA UNRANKED SER ENTREGUE?',
					text: 'O processo de entrega é IMEDIATO, assim que efetuar o pagamento será enviado um e-mail com os dados da sua conta unranked escolhida. Se você não ver o e-mail, verifique sua pasta de spam ou lixo eletrônico.',
				},
				4: {
					iconClass: 'icon fas fa-hand-holding-usd',
					title: 'COMO PODEMOS VENDER A PREÇOS TÃO BAIXOS?',
					text: 'Mantemos nossos preços baixos garantindo a satisfação dos nossos clientes. Queremos construir relacionamentos futuros com todos nossos clientes.',
				},
				5: {
					iconClass: 'icon fas fa-heartbeat',
					title: 'QUAL É A SUA GARANTIA?',
					text: 'Nossa garantia é de 30 dias é muito simples: se qualquer conta unranked adquirida através de nós for suspensa por qualquer defeito ou falha nossa, você receberá uma conta substituta gratuitamente e sua garantia é renovada. A garantia não cobre mal uso, como: Mau comportamento, linguagem ofensiva, abandono, uso de scripts, skin changer, trava lobby, feed intencional, etc.',
				},
				6: {
					iconClass: 'icon fas fa-ticket-alt',
					title: 'VOCÊS TEM CUPOM? COMO CONSIGO UM?',
					text: 'Sim, nós temos cupom, siga nossas redes sociais e nosso discord, de vez em quando colocamos alguns cupoms para trabalhar e você pode usá-lo como quiser! Ao escolher um produto no momento de fazer o pagamento, é só inserir o cupom no local indicado e clicar em Utilizar, o desconto será aplicado automaticamente.',
				},
				7: {
					iconClass: 'icon fas fa-medal',
					title: 'VOCÊS FAZEM SORTEIOS?',
					text: 'Sim, de vez em quando faremos sorteios de contas unranked level 30 e você resgata ela direto no nosso site, você escolhe a conta e nós entregamos ela para você.',
				},
			},
			footer: {
				contactUs: 'Contato',
				instagramId: 'mafiasmurfs',
				discordText: 'Nosso discord',
				discordLink: 'https://discord.gg/P6BPGmCGgx',
				email: 'suporte@mafiasmurfs.com',
				disclaimer: 'Mafia Smurfs NÃO é afiliado, patrocinado, endossado ou associado a League of Legends ou Riot Games, Inc. Todos os direitos autorais, marcas registradas, imagens e marcas de serviço pertencem aos seus respectivos proprietários.',
				disclaimer2: 'Aqui você escolhe qual fragmento de skin você quer que acompanhe sua smurf unranked level 30 +, nós temos as melhores smurfs do mercado, compre agora sua smurf.',
			},
			skins: {
				pageTitle: 'Mafia Smurfs - Escolha sua skin, smurf unranked level 30',
				countryMpagoScript: 'br',
				chooseYourRegion: 'Escolha sua skin, smurf unranked level 30',
				chooseYourSkin: 'Escolha sua Skin',
				regions: {
					las: 'LAS',
					br: 'Brasil',
				},
				selectAChampion: 'Selecione um campeão',
				placeholderChampInput: 'nome do campeão',
				errorNoStock: 'ERRO: Sem estoque disponível.',
				errorPleaseTryAgain: 'ERRO: Por favor, tente novamente.',
				errorCouponInvalidForQuantity: 'ERRO: cupom inválido para quantidade do produto.',
				errorCouponExpired: 'ERRO: o cupom expirou.',
				errorCouponDontExist: 'ERRO: o cupom não existe para o produto selecionado.',
				localCurrency: 'Real Brasil',
				localCurrencySymbol: 'R$',
				coupon: 'Cupom',
				generatePayment: 'Gerar pagamento',
				select: 'Selecionar',
				noStock: 'Fora de estoque',
				makeThePayment: 'Realizar pagamento',
				price: 'Preço',
				use: 'Usar',
				yourEmail: 'Seu e-mail',
				useCouponToGetDiscount: 'Use um cupom para ter um desconto',
				writeValidEmail: 'Escreva seu e-mail',
			},
		},
	};
	return dictionary[LANGUAGE];
};
