import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Menu from './Navbar';
import Footer from './Footer';

import { Link } from 'react-scroll';
import Spinner from '../components/Spinner';
import SpinnerSmall from '../components/SpinnerSmall';
import dict from '../utils/dictionary';
// import skins from '../../public/skins.json';
import { Helmet } from 'react-helmet';

const Skins = () => {
	const dictionary = dict()['skins'];

	const products = {
		las: [
			{
				idVerbose: 'las_skin',
				title: 'Unranked com Skin Específica',
				name: 'Unranked com Skin Específica',
				type: 'skin-unranked',
				price: 0,
				originalPrice: 0,
				region: 'las',
				capsuleType: 'be',
				description: ['Pelo menos nível 30', 'SEM MMR', '0% chance de ban', 'E-mail sem verificação', 'Garantia de 30 dias', 'Fragmento de Skin Escolhida'],
				startBe: 0,
				endBe: 100000,
			},
		],
		br: [
			{
				idVerbose: 'br_skin',
				title: 'Unranked com Skin Específica',
				name: 'Unranked com Skin Específica',
				type: 'skin-unranked',
				price: 0,
				originalPrice: 0,
				region: 'br',
				capsuleType: 'be',
				description: ['Pelo menos nível 30', 'SEM MMR', '0% chance de ban', 'e-mail sem verificação', 'Garantia de 30 dias', 'Fragmento de Skin Escolhida'],
				startBe: 0,
				endBe: 100000,
			},
		],
	};
	const [email, setEmail] = useState('');

	const [selectedProduct, setSelectedProduct] = useState();
	const [originalSkins, setOriginalSkins] = useState([]);
	const [skins, setSkins] = useState([]);
	const [currentSkinGroup, setCurrentSkinGroup] = useState([]);
	const [champion, setChampion] = useState('');
	const [isLoading, setIsLoading] = useState('');
	const [steps, setSteps] = useState('1');
	const [selectedSkin, setSelectedSkin] = useState({});
	const [region, setRegion] = useState('br');
	const [coupon, setCoupon] = useState('');
	const [mercadoPagoAvailable, setMercadoPagoAvailable] = useState(false);
	const [generatingPaymentLoading, setGeneratingPaymentLoading] = useState(false);
	const [error, setError] = useState('');
	const [discountedPrice, setDiscountedPrice] = useState({
		applied: false,
		discount: 0,
		price: 0,
	});

	useEffect(() => {
		getJSONSkins();
	}, []);

	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	const getJSONSkins = async () => {
		let skins = await axios.get('/skins.json');
		// console.log(skins);
		setSkins(skins.data[0]);
		setOriginalSkins(skins.data[0]);
	};
	const onChangeChampionSearcher = (event) => {
		if (event.target.value != '') {
			let skinsArray = {};

			Object.entries(originalSkins).forEach((skin) => {
				const regex = new RegExp(`${event.target.value.trim()}`, 'gi'); // gi is case insensitive
				if (skin[0].match(regex)) {
					skinsArray = { ...skinsArray, [skin[0]]: skin[1] };
				}
			});
			setSkins(skinsArray);
		} else {
			getJSONSkins();
		}
	};

	const getStockForSkinGroup = async (skinGroup, region) => {
		setDiscountedPrice({ discount: 0, price: 0, applied: false });
		setCoupon('');
		setError('');
		setIsLoading(true);
		let headers = {
			'Content-Type': 'application/json',
		};
		let stockRequest = await axios.post(`/api/v1/stock/skins/check`, { skinGroup, region }, headers);
		setCurrentSkinGroup(stockRequest.data.data);
		// setIsLoading(false); // Si dejamos esto comentado, false se va a poner solo cuando carguen las fotos,
		// Si le sacamos el comment, se displayeara todo sin que las imagenes hayan cargado
	};
	const onClickChampion = async (selectedChamp) => {
		setCoupon('');
		setError('');
		if (champion != selectedChamp) {
			setSteps(3);

			setChampion(selectedChamp);

			getStockForSkinGroup(originalSkins[selectedChamp], region);
		}
	};
	const onClickSkin = (skin) => {
		setDiscountedPrice({ discount: 0, price: 0, applied: false });
		setCoupon('');
		setError('');
		setMercadoPagoAvailable(false);
		setSteps(4);
		setSelectedSkin(skin);
		let newPricing = 0;
		currentSkinGroup.forEach((group) => {
			if (group.skinId === skin.skinId) {
				newPricing = group.price;
			}
		});
		setSelectedProduct({ ...products[region][0], originalPrice: newPricing, price: newPricing, skinId: skin.skinId, name: `Unranked - ${skin.skinName} (${region.toUpperCase()})` });
	};
	const onClickRegion = (newRegion) => {
		setCoupon('');
		setError('');
		setSteps(2);
		setChampion('');
		setRegion(newRegion);
	};

	const onLoad = () => {
		setIsLoading(false);
	};

	const onChangeEmail = (event) => {
		setEmail(event.target.value);
	};
	const onChangeCoupon = (event) => {
		setCoupon(event.target.value.toUpperCase());
	};
	const generatePreferenceMercadoPago = async () => {
		setGeneratingPaymentLoading(true);
		try {
			let headers = {
				'Content-Type': 'application/json',
			};

			let stockRequest = await axios.post(`/api/v1/stock/skins/buy/mercadopago`, { ...selectedProduct, email, coupon }, headers);

			let stockOutcome = stockRequest.data;

			if (stockOutcome.msgCode === 'PRODUCT_OUT_OF_STOCK') {
				setError(dictionary['errorNoStock']);
			}
			if (stockOutcome.msgCode === 'PAYMENT_CREATED') {
				var script = document.createElement('script');

				script.src = `https://www.mercadopago.com.${dictionary['countryMpagoScript']}/integrations/v1/web-payment-checkout.js`;
				script.type = 'text/javascript';
				script.dataset.preferenceId = stockOutcome.data.transactionId;
				document.getElementById('mpago-checkout').innerHTML = '';
				document.querySelector('#mpago-checkout').appendChild(script);
			}
		} catch (error) {
			setError(dictionary['errorPleaseTryAgain']);
		}
		setGeneratingPaymentLoading(false);
	};
	const checkCouponCode = async () => {
		// setGeneratingPaymentLoading(true);
		try {
			let headers = {
				'Content-Type': 'application/json',
			};

			let couponRequest = await axios.post(`/api/v1/stock/coupon/check`, { coupon, quantity: 1, price: selectedProduct.price, product: selectedProduct }, headers);

			let couponOutcome = couponRequest.data;

			if (couponOutcome.msgCode === 'INVALID_COUPON') {
				setError(dictionary['errorCouponInvalidForQuantity']);
			}
			if (couponOutcome.msgCode === 'INVALID_COUPON_EXPIRED') {
				setError(dictionary['errorCouponExpired']);
			}
			if (couponOutcome.msgCode === 'INVALID_COUPON_NOT_FOUND') {
				setError(dictionary['errorCouponDontExist']);
			}
			if (couponOutcome.msgCode === 'VALID_COUPON') {
				setSelectedProduct({ ...selectedProduct, price: couponOutcome.data.price, discount: couponOutcome.data.discount });
				setDiscountedPrice({ price: couponOutcome.data.price, discount: couponOutcome.data.discount, applied: true });
				setError('');
			}
		} catch (error) {
			setError(dictionary['errorPleaseTryAgain']);
		}
	};

	const onClickGeneratePaymentMercadoPago = () => {
		setError('');
		localStorage.setItem('email', email);
		let preferenceId = generatePreferenceMercadoPago();
		if (preferenceId != 'ERROR') {
			setMercadoPagoAvailable(true);
		}
	};
	const onClickCheckCoupon = () => {
		if (coupon) {
			checkCouponCode();
		}
	};

	return (
		<>
			<Helmet>
				{' '}
				<title>{dictionary['pageTitle']}</title>
			</Helmet>
			<div className='back-img' style={{ height: 'auto', backgroundAttachment: 'fixed' }}>
				<Menu isOnSkinsPage={true} />
				<section className='' style={{ height: '100%' }}>
					<div>
						<div className='skins-title ' style={{ marginTop: '50px' }}>
							<div className='container'>
								<div className='row'>
									<div className='col-md-12 col-sm-12 col-text-center d-align-center'>
										<h2 className='title' style={{ justifyContent: 'center', display: 'flex', color: '#dadada' }}>
											<span> {dictionary['chooseYourRegion']}</span>
										</h2>
									</div>
								</div>
							</div>
						</div>
						<div className={`container ${isLoading ? 'd-none' : 'region-container'}`}>
							<Link href='#' className={`region-item col-3 ${region === 'br' ? 'region-selected' : ''}`} activeClass='active' to='choose-champ' spy={true} smooth={true} offset={-70} duration={500} onClick={() => onClickRegion('br')} style={{ color: 'white', backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/br.webp")` }}>
								<span>{dictionary['regions']['br']}</span>
							</Link>
							{/* <Link href='#' className={`region-item col-3 ${region === 'las' ? 'region-selected' : ''}`} activeClass='active' to='choose-champ' spy={true} smooth={true} offset={-70} duration={500} onClick={() => onClickRegion('las')} style={{ color: 'white', backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/las.webp")` }}>
								<span>{dictionary['regions']['las']}</span>
							</Link> */}
							{/* <div style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/las.webp")` }} className={`region-item col-3 ${region === 'las' ? 'region-selected' : ''}`} onClick={() => onClickRegion('las')}>
							Latinoamerica Sur
						</div>
						<div style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/br.webp")` }} className={`region-item col-3 ${region === 'br' ? 'region-selected' : ''}`} onClick={() => onClickRegion('br')}>
							Brasil
						</div> */}
						</div>

						<div id='choose-champ' className='skins-title ' style={{ marginTop: '50px' }}>
							<div className='container'>
								<div className='row'>
									<div className='col-md-12 col-sm-12 col-text-center d-align-center'>
										<h2 className='title' style={{ justifyContent: 'center', display: 'flex', color: '#dadada' }}>
											<span> {dictionary['selectAChampion']}</span>
										</h2>
									</div>
								</div>
							</div>
						</div>

						<div className='container' style={{ marginTop: '50px' }}>
							<div className='champ-searcher'>
								<input maxLength={16} onChange={onChangeChampionSearcher} placeholder={` ${dictionary['placeholderChampInput']}`}></input>
							</div>
							<div className='champion-container'>
								{Object.keys(skins).map((skin) => (
									<Link href='#' key={skin} className={`champion-container-item ${champion === skin ? 'champion-container-selected' : ''}`} activeClass='active' to='choose-skin' spy={true} smooth={true} offset={-100} duration={500} onClick={() => onClickChampion(skin)} style={{ color: 'white' }}>
										{skin}
									</Link>
								))}
							</div>
						</div>

						<div id='choose-skin' className='step-3'>
							{steps >= 3 && (
								<>
									<div className='skins-title'>
										<div className='container'>
											<div className='row'>
												<div className='col-md-12 col-sm-12 col-text-center d-align-center'>
													<h2 className='title' style={{ justifyContent: 'center', display: 'flex', color: '#dadada' }}>
														<span>{dictionary['chooseYourSkin']}</span>
													</h2>
												</div>
											</div>
										</div>
									</div>

									<div className='container'>
										{isLoading && (
											<div className='d-flex justify-content-center'>
												<Spinner />
											</div>
										)}

										<div className='skin-container'>
											{currentSkinGroup.map((skin) => (
												<div key={skin.skinId} style={isLoading ? { display: 'none' } : { display: 'flex' }}>
													<div className={`skin-item-wrapper ${skin.isOnStock ? 'skin-item-active' : 'skin-item-disabled'} ${skin.skinId === selectedSkin.skinId ? 'skin-item-selected' : ''}`}>
														<div className='skin-item'>
															<div className='skin-price'>
																{' '}
																<span>
																	{dictionary['localCurrencySymbol']}
																	{skin.price}
																</span>
																<span className='skin-price-currency'>{dictionary['localCurrency']}</span>
															</div>
															<img onLoad={onLoad} className='skin-img' src={`/skins/${skin.skinId}.jpg`}></img>
															<div className='skin-title-container'>
																<span>{skin.skinName}</span>
															</div>
														</div>

														<Link activeClass='active' to={'payment'} spy={true} smooth={true} offset={-150} duration={500} onClick={() => onClickSkin(skin)}>
															<div className='buy-button'>
																<span>{skin.isOnStock ? dictionary['select'] : dictionary['noStock']}</span>
															</div>
														</Link>
													</div>
												</div>
											))}
										</div>
									</div>
								</>
							)}
						</div>

						<div id='payment' className='step-4'>
							{steps >= 4 && (
								<>
									<div className='skins-title'>
										<div className='container'>
											<div className='row'>
												<div className='col-md-12 col-sm-12 col-text-center d-align-center'>
													<h2 className='title' style={{ justifyContent: 'center', display: 'flex', color: '#dadada' }}>
														<span>{dictionary['makeThePayment']}</span>
													</h2>
												</div>
											</div>
										</div>
									</div>
									{selectedProduct ? (
										<div style={{ display: selectedProduct ? 'flex' : 'none', justifyContent: 'center', marginTop: '50px' }}>
											{!isLoading && (
												<div className='innerpage-decor d-none d-xl-block'>
													<div className='innerpage-faq1' style={{ bottom: '1px', right: '1px', top: 'auto' }}>
														<img src={`${process.env.PUBLIC_URL}/assets/images/money.webp`} alt='poro with essence' />
													</div>
													<div className='innerpage-faq2'>
														<img src={`${process.env.PUBLIC_URL}/assets/images/gold.webp`} alt='gold' />
													</div>
												</div>
											)}
											<div className='payment-skin'>
												<h2 style={{ fontSize: '27px', textAlign: 'center' }}>{selectedProduct.name}</h2>
												<div style={{}}>
													<h2 style={{ margin: '0px', fontSize: '20px' }}>
														{dictionary['price']}:{' '}
														<span style={discountedPrice.applied ? { textDecoration: 'line-through' } : {}}>
															{dictionary['localCurrencySymbol']}
															{selectedProduct.originalPrice}
														</span>{' '}
														{discountedPrice.applied ? `${dictionary['localCurrencySymbol']}${discountedPrice.price} (-${discountedPrice.discount}%)` : ''}
													</h2>
												</div>
												<div className='skins-description-payment'>
													{selectedProduct.description.map((desc) => (
														<div key={desc}>
															<i className='fas fa-check mr-2' style={{ color: '#568a56' }}></i>
															<span style={{ color: '#b7b7b7' }}>{desc}</span>
														</div>
													))}
												</div>

												<div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '20px' }}>
													<h2 style={{ fontSize: '20px', padding: '0px 20px 0px 0px', margin: '0px' }}>{dictionary['coupon']}</h2>
													<input maxLength={16} value={coupon} placeholder={dictionary['useCouponToGetDiscount']} onChange={onChangeCoupon} style={{ height: 'min-content', width: '100%' }}></input>
													<div onClick={onClickCheckCoupon} style={{ marginLeft: '20px', height: 'min-content' }} className={`btn btn-custom theme-color ${mercadoPagoAvailable ? 'btn-disabled' : ''}`}>
														{dictionary['use']}
													</div>
												</div>
												{!mercadoPagoAvailable && (
													<>
														<input placeholder={dictionary['yourEmail']} onChange={onChangeEmail} value={email}></input>
														<p style={{ margin: '5px 0px 5px 0px' }}>Estou ciente que é um produto/serviço digital e estou de acordo com os termos do site.</p>
														<div onClick={onClickGeneratePaymentMercadoPago} className={`btn btn-custom theme-color mt-3 ${!validateEmail(email) ? 'btn-disabled' : ''}`}>
															{!validateEmail(email) ? dictionary['writeValidEmail'] : dictionary['generatePayment']}
														</div>
													</>
												)}
												{error && (
													<div className='error-message'>
														<i style={{ color: '#d42121' }} className='fas fa-times mr-2'></i>
														<span>{error}</span>
													</div>
												)}
												{mercadoPagoAvailable && <div id='mpago-checkout' style={{ display: 'flex', justifyContent: 'center' }}></div>}
												{generatingPaymentLoading && (
													<div style={{ textAlign: 'center', marginTop: '20px' }}>
														<SpinnerSmall />
													</div>
												)}
											</div>
										</div>
									) : (
										<Spinner />
									)}

									{/* )} */}
								</>
							)}
						</div>
					</div>
				</section>
			</div>
			<div className='skins-title'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12 col-sm-12 col-text-center d-align-center'></div>
					</div>
				</div>
			</div>
			<Footer secondDisclaimer={true} />
		</>
	);
};

export default Skins;
