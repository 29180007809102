import './App.css';

import PageNotFound from './Pages/404';

import React from 'react';

import Home from './components/Home';

import Success from './Pages/Success';
import Pending from './Pages/Pending';
import Failed from './Pages/Failed';
import Skins from './components/Skins';
import TagManager from 'react-gtm-module';
import { Route, Switch } from 'react-router-dom';

function App() {
	const tagManagerArgs = {
		gtmId: 'GTM-PZNWRHS',
	};
	TagManager.initialize(tagManagerArgs);
	return (
		<div className='App'>
			<Switch>
				<Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
				<Route path={`${process.env.PUBLIC_URL}/success`} component={Success} />
				<Route path={`${process.env.PUBLIC_URL}/pending`} component={Pending} />
				<Route path={`${process.env.PUBLIC_URL}/failed`} component={Failed} />
				<Route path={`${process.env.PUBLIC_URL}/skins`} component={Skins} />

				<Route component={PageNotFound} />
			</Switch>
		</div>
	);
}

export default App;
