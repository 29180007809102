import React, { useEffect } from 'react';
import dict from '../utils/dictionary';

const PageNotFound = () => {
	const dictionary = dict()['404'];

	return (
		<div className='back-img'>
			<div className='authentication-form not-found'>
				<div className='innerpage-decor'>
					<div className='innerpage-circle1'>
						<img src='assets/images/heimer.webp' alt='' />
					</div>
					{/* <div className='innerpage-circle2'>
						<img src='assets/images/Testimonial1.png' alt='' />
					</div> */}
				</div>
				<div className='text-center'>
					<h2 className='error-font'>
						<span>4</span>
						<span>0</span>
						<span>4</span>
					</h2>
					<h2 className='f-bold'>{dictionary['notFound']}</h2>
					<div className='col-md-8 offset-md-2 col-12'>
						<h3>{dictionary['pageMovedOrNeverExisted']}</h3>
					</div>
					<div className='mt-5'>
						<a href={`${process.env.PUBLIC_URL}/`} className='btn btn-custom theme-color theme-color pt-2 pb-2'>
							{dictionary['backToMainPageButton']}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageNotFound;
