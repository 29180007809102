import React from 'react';

const SpinnerSmall = () => {
	return (
		<div className='spinner'>
			<div className='loadingio-spinner-eclipse-rwwg4mmokyt'>
				<div className='ldio-sb3nq7rp018'>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default SpinnerSmall;
